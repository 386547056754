/**
 * Component PdfViewer
 * @author: Luis Avello
 * @version 1.2.1
 * @updated 2023-07-27
 */
import React, { useState, useEffect, useRef } from "react";
import { Viewer, Worker } from "@react-pdf-viewer/core";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import * as pdfjs from "pdfjs-dist";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";

const PdfViewer = (props) => {
  const { data } = props;

  useEffect(() => {
    console.log("Pdfjs version " + pdfjs.version);
  }, []);

  const defaultLayoutPluginInstance = defaultLayoutPlugin();
  //const url1 = `https://unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;
  const url2 = "/pdf.worker.min.js";
  const url_w = url2;
  console.log("Pdf Worker v1: " + url_w);


  return (
    <div>
      <Worker workerUrl={url_w}>
        <Viewer fileUrl={data} plugins={[defaultLayoutPluginInstance]} />
      </Worker>
    </div>
  );
};

export default PdfViewer;
