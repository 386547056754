export const maping = {
  LABEL_DEFECTS_FRONT: "Etiqueta Frente",
  LABEL_DEFECTS_RECIPEBOOK: "Etiqueta Recetario",
  LABEL_DEFECTS_STRIP: "Etiqueta Strip",
  LABEL_DEFECTS_SHOULDER: "Etiqueta Hombro",
  LABEL_DEFECTS_NECK: "Etiqueta Cuello",
  LABEL_DEFECTS_OVAL: "Etiqueta Óvalo",

  DEFECT_X: "Etiqueta Torcida",
  DEFECT_AH: "Defecto de Impresión",
  DEFECT_Y: "Etiqueta Equivocada",
  DEFECT_AI: "Color Incorrecto",
  DEFECT_AA: "Falta de Etiqueta",
  DEFECT_AJ: "Invertidas",
  DEFECT_AB: "Leyenda Equivocada",
  DEFECT_AK: "Diferencia Excesiva en el Tono de Color",
  DEFECT_AC: "Etiqueta Arrugada",
  DEFECT_AD: "Etiqueta Rota",
  DEFECT_AL: "Sucia",
  DEFECT_AE: "Exceso de Pegamento",
  DEFECT_AM: "Etiqueta Pelada",
  DEFECT_AF: "Falta de Pegamento",
  DEFECT_AN: "Etiqueta Doblada",
  DEFECT_AG: "Fuera de Centro",
  DEFECT_Z_LABEL: "Otros",

  CAPS_DEFECTS_CAPS: "Tapas",
  CAPS_DEFECTS_CORK: "Corcho",
  CAPS_DEFECTS_CAPSULE: "Cápsula",

  DEFECT_Q: "Liqueo en Tapas",
  DEFECT_R: "Fecha Incorrecta",
  DEFECT_S: "Tapa Rota",
  DEFECT_T: "Diferencia Excesiva Tono de Color",
  DEFECT_U: "Puente Roto",
  DEFECT_V: "Código Incorrecto",
  DEFECT_W: "Tapa Floja",
  DEFECT_Z_CAPS: "Otros",

  LIQUID_DEFECTS: "Defectos Líquidos",

  DEFECT_M: "Color no Característico",
  DEFECT_N: "Materia Extraña",
  DEFECT_O: "Nivel Alto",
  DEFECT_P: "Nivel Bajo",
  DEFECT_Z_LIQUID: "Otros",

  PACKAGE_DEFECTS: "Defectos Envases",

  DEFECT_A: "Columpio",
  DEFECT_B: "Rajaduras",
  DEFECT_C: "Aguja",
  DEFECT_D: "Aristas Cortante",
  DEFECT_E: "Vidrio Suelto",
  DEFECT_F: "Vidrio Adherido",
  DEFECT_G: "Puente Roto",
  DEFECT_H: "Envase Rayado",
  DEFECT_I: "Falta de Codificación",
  DEFECT_J: "Envase Manchado",
  DEFECT_K: "Disparejo",
  DEFECT_L: "Fracturas que no afectan la resistencia",
  DEFECT_Z_PACKAGE: "Otros",

  CRITICAL: "Defectos Criticos",
  MAYOR: "Defectos Mayores",
  MINOR: "Defectos Menores",
  INSPECTED: "Cantidad Inspeccionada",
};
