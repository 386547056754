/**
 * Component Create Ticket
 * @author: Luis Avello
 * @version 1.1.0
 * @updated 2023-11-24
 *
 */
import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Unstable_Grid2";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import axios from "axios";

import {
  Divider,
  List,
  ListItem,
  ListItemText,
  Typography,
  Input,
  InputLabel,
} from "@mui/material";

import { maping } from "../utils/maping";
import { responseInfo, responseError } from "../utils/response_console";

const NewTicket = (props) => {
  const { forminstance_id } = props;
  const [open, setOpen] = useState(false);
  const [data, setData] = useState({
    line: { name: "-" },
    order: { number: "-", product_fullname: "-" },
  });
  const [pallet, setPallet] = useState("");
  const [trazabilidad, setTrazabilidad] = useState("");
  const [defects, setDefects] = useState({
    CRITICAL: [],
    MAYOR: [],
    MINOR: [],
  });
  const [revised, setRevised] = useState("");
  const [nonconforming, setNonconforming] = useState(0);
  const [observations, setObservations] = useState("");
  const [instance, setInstance] = useState(0);

  const urlForm = "/api/react/v1/quality/data_form";
  const urlPdf = "/api/react/v1/quality/data_pdf";
  const urlCreate = "/api/react/v1/tickets/create";
  const urlListInstances = "/api/react/v1/quality/list_instances";

  useEffect(() => {
    if (forminstance_id > 0)
      axios
        .get(urlForm, {
          params: { forminstance_id: forminstance_id },
        })
        .then((response) => {
          responseInfo(response, true);
          setData(response.data);
          setRevised(response.data.sections[7].sections[4].fields[4].value);
          console.log("Linea: " + response.data.line.name);
          const section_pallet = response.data.sections.find(
            (json) => json["code"] === "PRODUCT_INFO"
          );
          const subsection_pallet = section_pallet.sections.find(
            (json) => json["code"] === "PRODUCT_INFO_EMPTY"
          );
          const field_pallet = subsection_pallet.fields.find(
            (json) => json["code"] === "PALLET"
          );
          setPallet(field_pallet.value);
          const field_trazabilidad = subsection_pallet.fields.find(
            (json) => json["code"] === "TRACEABILITY_CODE"
          );
          setTrazabilidad(field_trazabilidad.value);
        })
        .catch((error) => {
          responseError(error);
        });
  }, [forminstance_id]);

  useEffect(() => {
    if (data.hasOwnProperty("formmain_id"))
      axios
        .get(urlListInstances, {
          params: { formmain_id: data.formmain_id },
        })
        .then((response) => {
          responseInfo(response, true);
          const instanceObject = response.data.find(
            (element) => element.forminstance_id === forminstance_id
          );
          const instance = parseInt(instanceObject.instance);
          setInstance(instance);
        });
  }, [data]);

  useEffect(() => {
    if (instance > 0)
      axios
        .get(urlPdf, {
          params: { formmain_id: data.formmain_id },
        })
        .then((response) => {
          responseInfo(response, true);
          const inspection = instance - 1;
          const new_defects = {
            CRITICAL: [],
            MAYOR: [],
            MINOR: [],
          };
          for (const [key1, value1] of Object.entries(
            response.data.body.CAPS_DEFECTS
          )) {
            for (const [key2, value2] of Object.entries(value1)) {
              for (const [key3, value3] of Object.entries(value2)) {
                if (value3.VALUES_ALT[inspection] != "") {
                  const type = key2.split("_")[3];
                  new_defects[type].push(
                    `${maping[key1]} - ${maping[key3]} ${value3.VALUES_ALT[inspection]}`
                  );
                }
              }
            }
          }
          for (const [key1, value1] of Object.entries(
            response.data.body.LABEL_DEFECTS
          )) {
            for (const [key2, value2] of Object.entries(value1)) {
              for (const [key3, value3] of Object.entries(value2)) {
                if (value3.VALUES_ALT[inspection] != "") {
                  const type = key2.split("_")[3];
                  new_defects[type].push(
                    `${maping[key1]} - ${maping[key3]} ${value3.VALUES_ALT[inspection]}`
                  );
                }
              }
            }
          }
          for (const [key1, value1] of Object.entries(
            response.data.body.LIQUID_DEFECTS
          )) {
            for (const [key2, value2] of Object.entries(value1)) {
              if (value2.VALUES_ALT[inspection] != "") {
                const type = key1.split("_")[2];
                new_defects[type].push(
                  `${maping[LIQUID_DEFECTS]} - ${maping[key2]} ${value2.VALUES_ALT[inspection]}`
                );
              }
            }
          }
          for (const [key1, value1] of Object.entries(
            response.data.body.PACKAGE_DEFECTS
          )) {
            for (const [key2, value2] of Object.entries(value1)) {
              if (value2.VALUES_ALT[inspection] != "") {
                const type = key1.split("_")[2];
                new_defects[type].push(
                  `${maping[PACKAGE_DEFECTS]} - ${maping[key2]} ${value2.VALUES_ALT[inspection]}`
                );
              }
            }
          }
          setDefects(new_defects);
        })
        .catch((error) => {
          console.log(error);
          responseError(error);
        });
  }, [instance]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCreateTicket = () => {
    console.log("Create Ticket");
    axios
      .get(urlCreate, {
        params: {
          formmain_id: data.formmain_id,
          forminstance_id: forminstance_id,
          production_order_id: data.order.id,
          revised: revised,
          nonconforming: nonconforming,
          description: observations,
          defects: encodeURIComponent(JSON.stringify(defects)),
        },
      })
      .then((response) => {
        responseInfo(response);
      })
      .catch((error) => {
        responseError(error);
      });
    setOpen(false);
  };

  return (
    <Container maxWidth="false">
      <Button variant="contained" onClick={handleClickOpen}>
        Nuevo Ticket
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Nuevo Ticket</DialogTitle>
        <DialogContent>
          <DialogContentText></DialogContentText>
          <TextField
            id="line"
            label="Linea"
            variant="standard"
            value={data.line.name}
            InputProps={{
              readOnly: true,
            }}
          />
          <TextField
            id="order"
            label="Orden"
            variant="standard"
            value={data.order.number}
            readOnly="true"
          />
          <TextField
            id="pallet"
            label="Pallet"
            variant="standard"
            value={pallet}
            InputProps={{
              readOnly: true,
            }}
          />
          <TextField
            id="traza"
            label="Trazabilidad"
            variant="standard"
            value={trazabilidad}
            InputProps={{
              readOnly: true,
            }}
          />
          <TextField
            id="product"
            label="Producto"
            variant="standard"
            value={data.order.product_fullname}
            fullWidth
            InputProps={{
              readOnly: true,
            }}
          />
          <TextField
            id="inspect"
            label="Inspección"
            variant="standard"
            value={instance}
            InputProps={{
              readOnly: true,
            }}
          />

          <TextField
            id="inspect"
            label="Cantidad Inspeccionada"
            type="number"
            variant="standard"
            value={revised}
            InputProps={{
              readOnly: true,
            }}
          />

          <Divider sx={{ paddingY: "6px" }} />

          {defects.CRITICAL.length > 0 ? (
            <Box className="flex">
              <Typography color={"red"} sx={{ paddingY: "6px" }}>
                Crítico:{" "}
              </Typography>
              {defects.CRITICAL.length > 0 && (
                <List dense={true} disablePadding>
                  {defects.CRITICAL.map((value) => (
                    <ListItem key={value}>
                      <ListItemText primary={value} />
                    </ListItem>
                  ))}
                </List>
              )}
            </Box>
          ) : (
            <></>
          )}

          {defects.MAYOR.length > 0 ? (
            <Box className="flex">
              <Typography color={"blue"} sx={{ paddingY: "6px" }}>
                Mayor:{" "}
              </Typography>
              <List dense={true} disablePadding>
                {defects.MAYOR.map((value) => (
                  <ListItem key={value}>
                    <ListItemText primary={value} />
                  </ListItem>
                ))}
              </List>
            </Box>
          ) : (
            <></>
          )}

          {defects.MINOR.length > 0 ? (
            <Box className="flex">
              <Typography color={"green"} sx={{ paddingY: "6px" }}>
                Menor:{" "}
              </Typography>
              <List dense={true} disablePadding>
                {defects.MINOR.map((value) => (
                  <ListItem key={value}>
                    <ListItemText primary={value} />
                  </ListItem>
                ))}
              </List>
            </Box>
          ) : (
            <></>
          )}

          <Divider sx={{ paddingY: "6px" }} />

          <TextField
            id="inspect"
            label="Cantidad No Conforme"
            type="number"
            variant="standard"
            value={nonconforming}
            onChange={(event) => setNonconforming(event.target.value)}
            autoFocus
            InputLabelProps={{
              shrink: true,
            }}
          />

          <TextField
            fullWidth
            multiline
            rows={4}
            size="small"
            label="Observaciones"
            variant="outlined"
            value={observations}
            onChange={(event) => setObservations(event.target.value)}
            sx={{ mt: 2 }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancelar</Button>
          <Button onClick={handleCreateTicket} variant="contained">
            Crear
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default NewTicket;
