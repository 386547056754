/**
 * Component FormTab
 * @author: Luis Avello
 * @version 1.16.0
 * @updated 2023-06-25
 */
import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import LibraryAddIcon from "@mui/icons-material/LibraryAdd";
import Tooltip from "@mui/material/Tooltip";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Badge from "@mui/material/Badge";

import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import FormLabel from "@mui/material/FormLabel";

import axios from "axios";
import { responseInfo, responseError } from "../utils/response_console";

import FormContent from "./form_content";

const FormTab = (props) => {
  const { data } = props;
  const urlListInstances = "/api/react/v1/quality/list_instances";
  const urlAddInstance = "/api/react/v1/quality/add_instance";
  const urlRemoveInstance = "/api/react/v1/quality/remove_instance";
  const urlLastOrders = "/api/react/v1/quality/last_orders";

  const [instance, setInstance] = React.useState(data.instance);
  const [listTabs, setListTabs] = React.useState([
    {
      instance: 1,
      forminstance_id: 0,
      label: "Inspección 1",
      info_order: "Linea 5 | 1000050 | 20001 Vino Carmenere",
    },
  ]);

  const [lastOrders, setLastOrders] = React.useState([]);
  const [selectedOrder, setSelectedOrder] = React.useState(data.order.id);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [openDialogOrders, setOpenDialogOrders] = React.useState(false);
  const [openSnack, setOpenSnack] = React.useState(false);
  const [messageSnack, setMessageSnack] = React.useState("");
  const [openProgress, setOpenProgress] = React.useState(false);
  const [numOrders, setNumOrders] = React.useState(0);

  useEffect(() => {
    if (data.formmain_id)
      axios
        .get(urlListInstances, {
          params: { formmain_id: data.formmain_id },
        })
        .then((response) => {
          responseInfo(response, true);
          setListTabs(response.data);
        })
        .catch((error) => {
          responseError(error);
        });
  }, [data]);

  useEffect(() => {
    let last_tab = listTabs[listTabs.length - 1];
    if (last_tab.forminstance_id > 0) {
      axios
        .get(urlLastOrders, {
          params: { forminstance_id: last_tab.forminstance_id },
        })
        .then((response) => {
          responseInfo(response, true);
          if (response.data.length > 1) {
            console.log("Ordenes mayor a 1, actualizo numero");
            setNumOrders(response.data.length);
          } else {
            setNumOrders(0);
            console.log("Solo orden " + selected_order_id);
          }
        })
        .catch((error) => {
          responseError(error);
        });
    }
  }, []);

  /*useEffect(() => {
    if (openProgress) {
      console.log('En progreso');
      console.log('Agregar Inspección');
      let new_value = listTabs.length + 1;
      setListTabs([
        ...listTabs,
        {
          instance: new_value,
          forminstance_id: 0,
          label: `Inspección ${new_value}`,
        },
      ]);
      setValue(new_value);
      setMessageSnack('Inspección Agregada');
      setOpenSnack(true);
      setOpenProgress(false);
    }
  }, [openProgress]);*/

  const handleCloseProgress = () => {
    setOpenProgress(false);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleOpenDialogOrders = (event) => {
    console.log("Open Dialog Orders");
    let last_tab = listTabs[listTabs.length - 1];
    console.log("forminstance_id " + last_tab.forminstance_id);
    if (last_tab.forminstance_id > 0) {
      axios
        .get(urlLastOrders, {
          params: { forminstance_id: last_tab.forminstance_id },
        })
        .then((response) => {
          responseInfo(response, true);
          let selected_order_id = response.data[0].id;
          setSelectedOrder(selected_order_id);
          setLastOrders(response.data);
          if (response.data.length > 1) {
            console.log("Ordenes mayor a 1, muestro listado");
            setNumOrders(response.data.length);
            setOpenDialogOrders(true);
          } else {
            //let selected_order_id = response.data[0].id;
            console.log("Solo orden " + selected_order_id);
            setNumOrders(0);
            //setSelectedOrder(selected_order_id);
            AddTab(selected_order_id);
          }
        })
        .catch((error) => {
          responseError(error);
        });
    } else {
      console.log("Agregar inspeccion dummy");
      let new_value = listTabs.length + 1;
      setListTabs([
        ...listTabs,
        {
          instance: new_value,
          forminstance_id: 0,
          label: `Inspección ${new_value}`,
          info_order: "Linea 1 | 400000025 | 70005 Ron Havana",
        },
      ]);
      setInstance(new_value);
      setMessageSnack("Inspección Agregada");
      setOpenSnack(true);
      setOpenProgress(false);
    }
  };

  const handleCloseDialogOrders = () => {
    console.log("Close Dialog Orders");
    setOpenDialogOrders(false);
    setSelectedOrder("0");
  };

  const handleChangeRadio = (event) => {
    console.log("Seteo Radio a valor " + event.target.value);
    setSelectedOrder(event.target.value);
  };

  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };

  const handleChangeTab = (event, newValue) => {
    console.log("Inpección cambia a " + newValue);
    setInstance(newValue);
  };
  /*
  const handleAddTab = (event) => {
    setOpenProgress(true);
    axios
      .get(urlAddInstance, {
        params: { formmain_id: data.formmain_id, order_id: data.order.id },
      })
      .then((response) => {
        responseInfo(response, true);
        console.log('Agregar Inspección');
        setListTabs([...listTabs, response.data]);
        setInstance(response.data.instance);
        setMessageSnack('Inspección Agregada');
        setOpenSnack(true);
        setOpenProgress(false);
      })
      .catch((error) => {
        responseError(error);
      });
  };*/

  const handleContinueAddTab = (event) => {
    setOpenDialogOrders(false);
    setOpenProgress(true);
    axios
      .get(urlAddInstance, {
        params: { formmain_id: data.formmain_id, order_id: selectedOrder },
      })
      .then((response) => {
        responseInfo(response, true);
        console.log("Agregar Inspección");
        setListTabs([...listTabs, response.data]);
        setInstance(response.data.instance);
        setMessageSnack("Inspección Agregada");
        setOpenSnack(true);
        setOpenProgress(false);
      })
      .catch((error) => {
        responseError(error);
      });
  };

  const AddTab = (order_id) => {
    console.log("AddTab order_id " + order_id);
    setOpenDialogOrders(false);
    setOpenProgress(true);
    axios
      .get(urlAddInstance, {
        params: { formmain_id: data.formmain_id, order_id: order_id },
      })
      .then((response) => {
        responseInfo(response, true);
        console.log("Agregar Inspección");
        setListTabs([...listTabs, response.data]);
        setInstance(response.data.instance);
        setMessageSnack("Inspección Agregada");
        setOpenSnack(true);
        setOpenProgress(false);
      })
      .catch((error) => {
        responseError(error);
      });
  };

  const handleDeleteTab = (event) => {
    console.log("Eliminar Última Inspección");
    setOpenDialog(true);
  };

  const deleteLastTab = () => {
    setOpenDialog(false);
    if (listTabs.length > 1) {
      let last_tab = listTabs[listTabs.length - 1];
      axios
        .get(urlRemoveInstance, {
          params: { forminstance_id: last_tab.forminstance_id },
        })
        .then((response) => {
          responseInfo(response, true);
        })
        .catch((error) => {
          responseError(error);
        });
      setInstance(listTabs.length - 1);
      setListTabs(listTabs.slice(0, listTabs.length - 1));
      setMessageSnack("Inspección Eliminada");
      setOpenSnack(true);
    }
  };

  return (
    <Box sx={{ width: "100%", pb: 10 }}>
      <Stack direction="row" justifyContent="space-between" spacing={0}>
        <Tabs value={instance} onChange={handleChangeTab} aria-label="pms tabs">
          {listTabs.map((tab, index) => (
            <Tab key={index} value={tab.instance} label={tab.label} />
          ))}
        </Tabs>
        <Stack direction="row" spacing={0}>
          {listTabs.length < 10 ? (
            <Tooltip title="Agregar Nueva Inspección">
              <IconButton
                color="success"
                aria-label="Prev"
                onClick={handleOpenDialogOrders}
              >
                <Badge badgeContent={numOrders} color="primary">
                  <LibraryAddIcon />
                </Badge>
              </IconButton>
            </Tooltip>
          ) : null}
          {listTabs.length > 1 ? (
            <Tooltip title="Eliminar Última Inspección">
              <IconButton
                color="error"
                aria-label="Prev"
                onClick={handleDeleteTab}
              >
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          ) : null}
        </Stack>
        <Dialog
          open={openDialog}
          onClose={handleCloseDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"¿Desea eliminar la última inspección?"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Si elimina la inspección se perderán todos los datos que se han
              ingresado hasta el momento en dicha inspección.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDialog}>Cancelar</Button>
            <Button onClick={deleteLastTab} autoFocus>
              Aceptar
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={openDialogOrders}
          onClose={handleCloseDialogOrders}
          aria-labelledby="orders-dialog-title"
          aria-describedby="orders-dialog-description"
        >
          <DialogTitle id="orders-dialog-title">
            {"Seleccione la orden para asignar la inspección"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description"></DialogContentText>
            <FormControl fullWidth required={true}>
              <FormLabel id={"pms-radio-buttons-group-label-"}>
                {"Ordenes de Producción"}
              </FormLabel>
              <RadioGroup
                aria-labelledby={"pms-radio-buttons-group-label-"}
                name={"pms-radio-buttons-group-"}
                value={selectedOrder}
                onChange={handleChangeRadio}
              >
                {lastOrders.map((order, index) => (
                  <FormControlLabel
                    key={order.id}
                    value={order.id}
                    control={<Radio size="small" />}
                    label={order.info_order}
                  />
                ))}
              </RadioGroup>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDialogOrders}>Cancelar</Button>
            <Button onClick={handleContinueAddTab} autoFocus>
              Aceptar
            </Button>
          </DialogActions>
        </Dialog>

        <Snackbar
          open={openSnack}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          autoHideDuration={4000}
          onClose={handleCloseSnack}
        >
          <Alert
            variant="filled"
            onClose={handleCloseSnack}
            severity="success"
            sx={{ width: "100%" }}
          >
            {messageSnack}
          </Alert>
        </Snackbar>
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={openProgress}
          onClick={handleCloseProgress}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </Stack>
      {listTabs.map((tab, index) =>
        tab.instance == instance ? (
          <Box key={tab.instance}>
            <FormContent
              data={data}
              forminstance_id={tab.forminstance_id}
              info_order={tab.info_order}
            />
          </Box>
        ) : null
      )}
    </Box>
  );
};

export default FormTab;
